import React, { Component } from 'react'
import PropTypes from 'prop-types'

class LogoNode extends Component {
  render () {
    return (
      <div className='logo' data-testid={this.props.dataTestId}>
        {!!this.props.logoUrl &&
        <a href='/'>
          <img
            alt={this.props.siteName}
            src={this.props.logoUrl}
            className='logo__image'
          />
        </a>
        }
      </div>
    )
  }
}

LogoNode.propTypes = {
  siteName: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  dataTestId: PropTypes.string.isRequired
}

export default LogoNode
