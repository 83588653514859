import { connect } from 'react-redux'

import SearchBar from 'components/hub-page/views/sections/navigations/search-bar'

const mapStateToProps = (state, ownProps) => {
  return ({
    textColor: (state.homePageRevision.revision.sections && state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarTextColor) || '#fff',
    dataTestId: 'participant-hubs-views-navigations-section-search-bar'
  })
}

export default connect(mapStateToProps)(SearchBar)
