import { connect } from 'react-redux'

import NavLinks from 'components/home-page/views/sections/navigations/mobile/nav-links'

const mapStateToProps = (state, ownProps) => ({
  navigationLinks: state.adminHeaderLinks.headerLinks,
  backgroundColor: state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarBackgroundColor,
  textColor: state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarTextColor
})

export default connect(mapStateToProps)(NavLinks)
