export default function getLinkColor (theme, themeName, revision, layout) {
  if (theme && themeName) {
    if (revision && layout) {
      if (!revision.published) {
        switch (themeName) {
          case 'bondi':
            return theme.color2

          case 'torquay2':
          case 'coral_bay':
          case 'whitehaven2':
            return theme.color3

          default:
            return theme.color1
        }
      } else if (revision.published && !layout.selectedColorPalette) {
        return theme.color2
      } else if (revision.published && !!layout.selectedColorPalette) {
        if (layout && Object.keys(layout.colorMapping).length > 0) {
          const linkColor = layout.colorMapping.linkColor
          return linkColor === '$brand-color' ? theme.brandColor : linkColor
        } else return theme.brandColor
      } else return '#1890FF'
    }
  }
}
