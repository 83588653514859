import { connect } from 'react-redux'

import SearchBar from 'components/home-page/views/sections/navigations/search-bar'

const mapStateToProps = (state, ownProps) => {
  return ({
    textColor: state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarTextColor
  })
}

export default connect(mapStateToProps)(SearchBar)
