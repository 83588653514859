import React, { Component } from 'react'
import PropTypes from 'prop-types'

class NavLinks extends Component {
  componentDidMount () {
    if (typeof MetisMenu === 'function') {
      const dropdownMenu = new MetisMenu('#js-nav-links').on('shown.metisMenu', function (event) {
        window.addEventListener('click', function dropdown (e) {
          if (!event.target.contains(e.target)) {
            dropdownMenu.hide(event.detail.shownElement)

            window.removeEventListener('click', dropdown)
          }
        })
      })
    }
  }

  getLinkUrl = (item) => {
    switch (item.linkableType) {
      case 'Hub':
      case 'Project':
      case 'StaticPage': return `/${item.linkableUrl}`

      default: return item.linkableUrl
    }
  }

  dropdownNode (item, index) {
    if (item.children.length) {
      return (
        <li key={index} className='nav-links__list__list-item'>
          <a
            href={this.getLinkUrl(item)}
            aria-expanded='false'
            className='nav-links__list__list-item__link'
          >
            <span>{item.title}</span>
          </a>
          <ul
            className='nav-links__list__list-item__child'
            style={{
              backgroundColor: this.props.backgroundColor,
              color: this.props.textColor
            }}
          >
            {item.children.map((child, childIndex) => {
              return this.dropdownNode(child, childIndex)
            })}
          </ul>
        </li>
      )
    }
    return (
      <li
        key={index}
        className='nav-links__list__list-item'
      >
        <a
          href={this.getLinkUrl(item)}
          className='metismenu nav-links__list__list-item__link'
        >
          <span>{item.title}</span>
        </a>
      </li>
    )
  }

  render () {
    const navLinks = this.props.navigationLinks.map(attributes => ({ ...attributes }))

    navLinks.map(l => !l.children && (l.children = []))

    const links = navLinks.filter(l => l.parentId === null)
    const childrenLinks = navLinks.filter(l => l.parentId !== null)
    const grandChildrenLinks = childrenLinks

    /**
     * Construct nested json from data. Find all children that belongs to the current
     * parent and add it to the 'children' key of the parent.
     */
    links.map(parent => {
      const children = childrenLinks.filter(
        l => l.parentId === parseInt(parent.id, 10)
      )

      children.map(child => {
        grandChildrenLinks.map((l, index) => {
          child.id === l.id && grandChildrenLinks.splice(index, 1)
        })
      })

      parent.children = children
    })

    links.map(parent => {
      if (!parent.children.length) {
        return false
      }

      parent.children.map(child => {
        const grandChildren = childrenLinks.filter(
          l => l.parentId === parseInt(child.id, 10)
        )

        grandChildren.map(child => {
          grandChildrenLinks.map((l, index) => {
            child.id === l.id && grandChildrenLinks.splice(index, 1)
          })
        })

        child.children = grandChildren
      })
    })

    return (
      <div className='nav-links' data-testid={this.props.dataTestId}>
        <ul className='metismenu nav-links__list' id='js-nav-links'>
          {links.map((item, index) => {
            return this.dropdownNode(item, index)
          })}
        </ul>
      </div>
    )
  }
}

NavLinks.propTypes = {
  navigationLinks: PropTypes.array,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  dataTestId: PropTypes.string.isRequired
}

export default NavLinks
