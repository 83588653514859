import { connect } from 'react-redux'

import Logo from 'components/hub-page/views/sections/navigations/logo'

const mapStateToProps = (state) => {
  return ({
    logoUrl: state.currentSite.site.logoUrl,
    siteName: state.currentSite.site.name,
    dataTestId: 'participant-hubs-views-navigations-section-logo'
  })
}

export default connect(mapStateToProps)(Logo)
