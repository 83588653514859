import { connect } from 'react-redux'

import NavLinks from 'components/hub-page/views/sections/navigations/mobile/nav-links'

const mapStateToProps = (state, ownProps) => {
  const homePageRevision = state.homePageRevision.revision.sections

  const props = {
    navigationLinks: state.adminHeaderLinks.headerLinks,
    backgroundColor: (homePageRevision && state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarBackgroundColor) ||
      state.hubPageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarBackgroundColor,
    textColor: (homePageRevision && state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarTextColor) ||
      state.hubPageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarTextColor,
    dataTestId: 'participant-hubs-views-navigations-section-nav-links'
  }

  return props
}
export default connect(mapStateToProps)(NavLinks)
