import { SiteAdmin, PlatformAdmin, HubAdmin, ProjectAdmin } from 'lib/roles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'next/router'

const ProfileLinks = ({ router }) => {
  const { t } = useTranslation(['admin-layout', 'navigation'])
  const { site } = useSelector((state) => state.currentSite)
  const { features } = useSelector((state) => state.features)
  const { revision } = useSelector((state) => state.homePageRevision)
  const backgroundColor = revision?.sections[0].theme.mainNavbarBackgroundColor
  const textColor = revision?.sections[0].theme.mainNavbarTextColor
  const { user } = useSelector((state) => state.currentUser)
  const { roleName } = user

  const generateProfileEditLink = () => {
    switch (roleName) {
      case 'platform_admin':
        return '/platform/profile/edit'

      case 'site_admin':
      case 'hub_admin':
      case 'project_admin':
        return `/platform/profile/edit`

      case 'participant':
        return '/profile'

      default:
        return '/profile'
    }
  }
  const handleClick = async () => {
    const isSiteBlocked = site.blocked
    const isSiteOnboardingEnabled =
      features?.enableSiteOnboarding && features.enableSiteOnboarding.enabled

    const response = await fetch('/admin/api/auth/logout', {
      method: 'DELETE'
    })

    if (response.ok) {
      const isAnAdmin =
        [SiteAdmin, PlatformAdmin, HubAdmin, ProjectAdmin].indexOf(roleName) !==
        -1
      const showAdminLoginPage =
        isSiteBlocked && isAnAdmin && isSiteOnboardingEnabled

      if (showAdminLoginPage) {
        router.push('/admin/login')
      } else {
        router.push('/login')
      }
    }
  }

  const isAdminUIDashboardEnabled = () => features?.['switch_to_admin_ui']?.enabled && features?.['reporting_dashboard_v2']?.enabled ? '/adm' : '/admin/dashboard'

  return (
    <div className='profile-links auth-links'>
      <ul className='auth-links__list'>
        <li
          className='auth-links__list__list-item'
          style={{
            backgroundColor: backgroundColor
          }}
        >
          <ul
            className='auth-links__list__list-item__child'
            style={{
              backgroundColor: backgroundColor,
              color: textColor
            }}
          >
            <li className='auth-links__list__list-item'>
              <a
                href={generateProfileEditLink()}
                className='auth-links__list__list-item__link'
              >
                {t('navigation:navigation.myAccount')}
              </a>
            </li>
            {[
              'platform_admin',
              'site_admin',
              'hub_admin',
              'project_admin'
            ].indexOf(roleName) > -1 && (
              <li className='auth-links__list__list-item'>
                <a
                  href='/admin/projects'
                  aria-expanded='false'
                  className='auth-links__list__list-item__link'
                >
                  {t('admin-layout:adminLayout.projects')}
                </a>
              </li>
            )}
            {[
              'platform_admin',
              'site_admin',
              'hub_admin',
              'project_admin'
            ].indexOf(roleName) > -1 && (
              <li className='auth-links__list__list-item'>
                <a
                  href={isAdminUIDashboardEnabled()}
                  aria-expanded='false'
                  className='auth-links__list__list-item__link'
                >
                  {t('admin-layout:adminLayout.dashboard')}
                </a>
              </li>
            )}
            {['platform_admin', 'site_admin'].indexOf(roleName) > -1 && (
              <li className='auth-links__list__list-item'>
                <a
                  href='/admin/sites/editors/home-page'
                  aria-expanded='false'
                  className='auth-links__list__list-item__link'
                >
                  {t('admin-layout:adminLayout.appearance')}
                </a>
              </li>
            )}
            <li className='auth-links__list__list-item'>
              <a
                href='#!'
                onClick={handleClick}
                className='auth-links__list__list-item__link'
              >
                {t('admin-layout:adminLayout.logout')}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}
export default withRouter(ProfileLinks)
