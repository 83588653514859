import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { withTranslation } from 'i18n'
import { SiteAdmin, PlatformAdmin, HubAdmin, ProjectAdmin } from 'lib/roles'
import UserAvatar from '../user-avatar'

class AuthLinks extends Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    if (this.props.user.id && this.props.user.roleName && typeof MetisMenu === 'function') {
      const dropdownMenu = new MetisMenu('#js-auth-links').on('shown.metisMenu', function (event) {
        window.addEventListener('click', function dropdown (e) {
          if (!event.target.contains(e.target)) {
            dropdownMenu.hide(event.detail.shownElement)

            window.removeEventListener('click', dropdown)
          }
        })
      })
    }
  }

  async handleClick () {
    const { isSiteBlocked, currentUserRole, isSiteOnboardingEnabled, router } = this.props

    const response = await fetch('/admin/api/auth/logout', { method: 'DELETE' })

    if (response.ok) {
      const isAnAdmin = [SiteAdmin, PlatformAdmin, HubAdmin, ProjectAdmin].indexOf(currentUserRole) !== -1
      const showAdminLoginPage = isSiteBlocked && isAnAdmin && isSiteOnboardingEnabled

      if (showAdminLoginPage) {
        router.push('/admin/login')
      } else {
        router.push('/login')
      }
    }
  }

  generateProfileEditLink () {
    switch (this.props.user.roleName) {
      case 'platform_admin':
        return '/platform/profile/edit'

      case 'site_admin':
      case 'hub_admin':
      case 'project_admin':
        return `/admin/people/administrators/${this.props.user.id}`

      case 'participant':
        return '/profile'

      default:
        return '/profile'
    }
  }

  isAdminUIDashboardEnabled () { return this.props.isSwitchToAdminUIEnabled && this.props.isReportingOverviewEnabled ? '/adm' : '/admin/dashboard' }

  render () {
    if (this.props.user.id && this.props.user.roleName) {
      return (
        <div className='auth-links' data-testid={this.props.dataTestId}>
          <ul className='metismenu auth-links__list' id='js-auth-links' tabIndex={-1}>
            <li className='auth-links__list__list-item' tabIndex={-1}>
              <a
                href='#'
                aria-expanded='false'
                className='metismenu profile-info'
              >
                <UserAvatar />
                <span>{this.props.user.login}</span>
              </a>
              <ul
                className='auth-links__list__list-item__child'
                style={{
                  backgroundColor: this.props.backgroundColor,
                  color: this.props.textColor
                }}
              >
                <li className='auth-links__list__list-item'>
                  <a
                    href={this.generateProfileEditLink()}
                    className='metismenu auth-links__list__list-item__link'
                  >
                    {this.props.t('admin-layout:adminLayout.myAccount')}
                  </a>
                </li>
                {['platform_admin',
                  'site_admin',
                  'hub_admin',
                  'project_admin'
                ].indexOf(this.props.user.roleName) >
                  -1 && (
                  <li className='auth-links__list__list-item'>
                    <a
                      href='/admin/projects'
                      aria-expanded='false'
                      className='metismenu auth-links__list__list-item__link'
                    >
                      {this.props.t('admin-layout:adminLayout.projects')}
                    </a>
                  </li>
                )}
                {[
                  'platform_admin',
                  'site_admin',
                  'hub_admin',
                  'project_admin'
                ].indexOf(this.props.user.roleName) >
                  -1 && (
                  <li className='auth-links__list__list-item'>
                    <a
                      href={this.isAdminUIDashboardEnabled()}
                      aria-expanded='false'
                      className='metismenu auth-links__list__list-item__link'
                    >
                      {this.props.t('admin-layout:adminLayout.dashboard')}
                    </a>
                  </li>
                )}
                {['platform_admin', 'site_admin'].indexOf(this.props.user.roleName) >
                  -1 && (
                  <li className='auth-links__list__list-item'>
                    <a
                      href='/admin/sites/editors/hub-page'
                      aria-expanded='false'
                      className='metismenu auth-links__list__list-item__link'
                    >
                      {this.props.t('admin-layout:adminLayout.appearance')}
                    </a>
                  </li>
                )}
                <li className='auth-links__list__list-item'>
                  <a
                    href='#!'
                    onClick={this.handleClick}
                    className='auth-links__list__list-item__link'
                  >
                    {this.props.t('admin-layout:adminLayout.logout')}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      )
    } else {
      return (
        <ul className='auth-links__list' data-testid={this.props.dataTestId}>
          <li className='auth-links__list__list-item'>
            <a
              href={`/${this.props.data.signInUrl}`}
              className='auth-links__list__list-item__link'
            >
              {this.props.data.signInText === 'Sign in' ? this.props.t('navigation:navigation.headerLink.signIn') : this.props.data.signInText}
            </a>
          </li>
          {!this.props.disableSignup &&
            <li className='auth-links__list__list-item'>
              <a
                href={`/${this.props.data.signUpUrl}`}
                className='auth-links__list__list-item__link'
              >
                {this.props.data.signUpText === 'Register' ? this.props.t('navigation:navigation.headerLink.signUp') : this.props.data.signUpText}
              </a>
            </li>
          }
        </ul>
      )
    }
  }
}

AuthLinks.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  user: PropTypes.object,
  disableSignup: PropTypes.bool,
  isSiteBlocked: PropTypes.bool.isRequired,
  isSiteOnboardingEnabled: PropTypes.bool.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  dataTestId: PropTypes.string.isRequired,
  isSwitchToAdminUIEnabled: PropTypes.bool.isRequired,
  isReportingOverviewEnabled: PropTypes.bool.isRequired
}

export default withTranslation(['admin-layout', 'navigation'])(withRouter(AuthLinks))
