import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'i18n'

class SearchBar extends Component {
  render () {
    return (
      <div className='search-bar' style={{ backgroundColor: `${this.props.backgroundColor}` }} role='search'>
        <form
          action='/search'
          acceptCharset='UTF-8'
          method='get'
        >
          <div className='search-bar__container'>
            <span className='icon'><i className='bx bx-search' /></span>
            <input
              className='search-bar__input'
              aria-label={this.props.t('common:common.search')}
              placeholder={this.props.t('common:common.search')}
              style={{
                border: `1px solid ${this.props.textColor}`,
                backgroundColor: `${this.props.backgroundColor}`
              }}
              name='query'
              type='search'
              defaultValue=''
            />
          </div>
        </form>
        <style>
          {`
            .search-bar__input::placeholder {
              color: ${this.props.textColor} !important;
              opacity: 1;
            }
            .search-bar__input:-ms-input-placeholder {
              color: ${this.props.textColor} !important;
            }
            .search-bar__input::-ms-input-placeholder {
              color: ${this.props.textColor} !important;
            }
          `}
        </style>
      </div>
    )
  }
}

SearchBar.propTypes = {
  textColor: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
}

export default withTranslation(['admin-layout', 'common'])(SearchBar)
