import { connect } from 'react-redux'

import Logo from 'components/home-page/views/sections/navigations/logo'

const mapStateToProps = (state) => {
  return ({
    logoUrl: state.currentSite.site.logoUrl,
    siteName: state.currentSite.site.name
  })
}

export default connect(mapStateToProps)(Logo)
