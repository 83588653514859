import { useSelector } from 'react-redux'

const UserAvatar = () => {
  const { imageUrl } = useSelector((state) => state.currentUser.user)

  return imageUrl ? (
    <img
      alt='avatar-img'
      src={imageUrl}
      className='avatar user-settings-avatar-small'
    />
  ) : (
    <img
      alt='avatar-placeholder'
      src='/static/assets/images/participant-header/avatar-icon-big.svg'
      className='dummy-avatar-icon'
    />
  )
}
export default UserAvatar
