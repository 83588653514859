import React, { Component } from 'react'
import PropTypes from 'prop-types'

class NavLinks extends Component {
  getLinkUrl = (item) => {
    switch (item.linkableType) {
      case 'Hub':
      case 'Project':
      case 'StaticPage': return `/${item.linkableUrl}`

      default: return item.linkableUrl
    }
  }

  dropdownNode (item, index) {
    if (!item.children.length) {
      return (
        <li
          key={index}
          className='nav-links__list__list-item bg-white user-block' style={{
            backgroundColor: this.props.loggedInUser === 'admin' && this.props.backgroundColor,
            color: this.props.textColor
          }}>
          <a
            href={this.getLinkUrl(item)}
            aria-expanded='false'
            className='metismenu nav-links__list__list-item__link'
            style={{
              color: this.props.textColor
            }}
          >
            <span>{item.title}</span>
          </a>
        </li>
      )
    }

    return (
      <li
        key={index}
        className='nav-links__list__list-item bg-white user-block-child'
        style={{
          backgroundColor: this.props.loggedInUser === 'admin' && this.props.backgroundColor,
          color: this.props.textColor
        }}>
        <a
          href={this.getLinkUrl(item)}
          aria-expanded='false'
          className='nav-links__list__list-item__link'
          style={{
            color: this.props.textColor
          }}
        >
          <span>{item.title}</span>
        </a>
        <ul
          className='nav-links__list__list-item__child'
          style={{
            backgroundColor: this.props.loggedInUser === 'admin' && this.props.backgroundColor,
            color: this.props.textColor
          }}
        >
          {item.children.map((child, childIndex) => {
            return this.dropdownNode(child, childIndex)
          })}
        </ul>
      </li>
    )
  }

  render () {
    const navLinks = this.props.navigationLinks.map(attributes => ({ ...attributes }))

    navLinks.map(l => !l.children && (l.children = []))

    const links = navLinks.filter(l => l.parentId === null)
    const childrenLinks = navLinks.filter(l => l.parentId !== null)
    const grandChildrenLinks = childrenLinks

    /**
     * Construct nested json from data. Find all children that belongs to the current
     * parent and add it to the 'children' key of the parent.
     */
    links.map(parent => {
      const children = childrenLinks.filter(
        l => l.parentId === parseInt(parent.id, 10)
      )

      children.map(child => {
        grandChildrenLinks.map((l, index) => {
          child.id === l.id && grandChildrenLinks.splice(index, 1)
        })
      })

      parent.children = children
    })

    links.map(parent => {
      if (!parent.children.length) {
        return false
      }

      parent.children.map(child => {
        const grandChildren = childrenLinks.filter(
          l => l.parentId === parseInt(child.id, 10)
        )

        grandChildren.map(child => {
          grandChildrenLinks.map((l, index) => {
            child.id === l.id && grandChildrenLinks.splice(index, 1)
          })
        })

        child.children = grandChildren
      })
    })

    return (
      <div className='nav-links' data-testid={this.props.dataTestId}>
        <ul className='metismenu nav-links__list'>
          {links.map((item, index) => {
            return this.dropdownNode(item, index)
          })}
        </ul>
      </div>
    )
  }
}

NavLinks.propTypes = {
  navigationLinks: PropTypes.array,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  dataTestId: PropTypes.string.isRequired
}

export default NavLinks
