import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'i18n'
import { withRouter } from 'next/router'
import MobileNavLinks from 'containers/hub-page/views/sections/navigations/mobile/nav-links'
import SearchBar from 'containers/hub-page/views/sections/navigations/search-bar'

class AuthLinks extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    if (this.props.user.id && this.props.user.roleName) {
      return (
        <div
          className='auth-links-container'
          style={{
            backgroundColor: this.props.backgroundColor
          }}
          data-testid={this.props.dataTestId}
        >
          <MobileNavLinks
            sectionId={this.props.sectionId}
            loggedInUser='admin'
          />
          <SearchBar
            sectionId={this.props.sectionId}
            backgroundColor={this.props.backgroundColor}
          />
        </div>
      )
    } else {
      return (
        <ul className='auth-links__list' data-testid={this.props.dataTestId}>
          <li className='auth-links__list__list-item'>
            <a
              href={`/${this.props.data.signInUrl}`}
              className='auth-links__list__list-item__link'
            >
              {this.props.data.signInText === 'Sign in' ? this.props.t('navigation:navigation.headerLink.signIn') : this.props.data.signInText}
            </a>
          </li>
          {!this.props.disableSignup &&
            <li className='auth-links__list__list-item'>
              <a
                href={`/${this.props.data.signUpUrl}`}
                className='auth-links__list__list-item__link'
              >
                {this.props.data.signUpText === 'Register' ? this.props.t('navigation:navigation.headerLink.signUp') : this.props.data.signUpText}
              </a>
            </li>
          }
        </ul>
      )
    }
  }
}

AuthLinks.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  user: PropTypes.object,
  disableSignup: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired
}

export default withTranslation(['admin-layout', 'navigation'])(withRouter(AuthLinks))
