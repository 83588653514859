import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OpenMenuIcon from 'static/assets/images/common/menu-regular-24.png'
import CloseMenuIcon from 'static/assets/images/common/x-regular-24.png'
import { withTranslation } from 'i18n'

class HamburgerIcon extends Component {
  render () {
    if (document.getElementById('metismenu-container') && document.getElementById('metismenu-container').classList.contains('display-none')) {
      this.props.onClick()
    }
    return (
      <button
        className='hamburger-icon'
        type='button'
        aria-expanded={this.props.isMobileMenuVisible}
        aria-label={this.props.t('editor:editor.toggleMainMenu')}
        onClick={this.props.onClick}
      >
        {this.props.isMobileMenuVisible
          ? <img src={CloseMenuIcon} alt={this.props.t('editor:editor.closeMenu')} id='hamburger-menu' width='35' height='35' />
          : <img src={OpenMenuIcon} alt={this.props.t('editor:editor.openMenu')} id='hamburger-menu' width='35' height='35' />
        }
      </button>
    )
  }
}

HamburgerIcon.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isMobileMenuVisible: PropTypes.bool.isRequired
}

export default withTranslation(['editor'])(HamburgerIcon)
