import { connect } from 'react-redux'

import AuthLinks from 'components/home-page/views/sections/navigations/desktop/auth-links'

const mapStateToProps = (state, ownProps) => ({
  data: state.homePageRevision.revision.sections[ownProps.sectionId].data[0],
  backgroundColor: state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarBackgroundColor,
  textColor: state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarTextColor,
  user: state.currentUser.user,
  disableSignup: state.siteSettings.settings.disableSignup,
  isSiteBlocked: state.currentSite.site.blocked,
  isSiteOnboardingEnabled: state.features.features.hasOwnProperty('enable_site_onboarding') && state.features.features.enable_site_onboarding.enabled,
  currentUserRole: state.currentUser.user.roleName,
  isSwitchToAdminUIEnabled: state.features.features.hasOwnProperty('switch_to_admin_ui') && state.features.features.switch_to_admin_ui.enabled,
  isReportingOverviewEnabled: state.features.features.hasOwnProperty('reporting_dashboard_v2') && state.features.features.reporting_dashboard_v2.enabled
})

export default connect(mapStateToProps)(AuthLinks)
