import { connect } from 'react-redux'

import AuthLinks from 'components/hub-page/views/sections/navigations/mobile/auth-links'

const mapStateToProps = (state, ownProps) => {
  const homePageRevision = state.homePageRevision.revision.sections

  const props = {
    data: (homePageRevision && state.homePageRevision.revision.sections[ownProps.sectionId].data[0]) ||
      state.hubPageRevision.revision.sections[ownProps.sectionId].data[0],
    backgroundColor: (homePageRevision && state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarBackgroundColor) ||
      state.hubPageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarBackgroundColor,
    textColor: (homePageRevision && state.homePageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarTextColor) ||
      state.hubPageRevision.revision.sections[ownProps.sectionId].theme.mainNavbarTextColor,
    user: state.currentUser.user,
    disableSignup: state.siteSettings.settings.disableSignup,
    isSiteBlocked: state.currentSite.site.blocked,
    isSiteOnboardingEnabled: state.features.features.hasOwnProperty('enable_site_onboarding') && state.features.features.enable_site_onboarding.enabled,
    currentUserRole: state.currentUser.user.roleName,
    dataTestId: 'participant-hubs-views-navigations-section-auth-links',
    isSwitchToAdminUIEnabled: state.features.features.hasOwnProperty('switch_to_admin_ui') && state.features.features.switch_to_admin_ui.enabled,
    isReportingOverviewEnabled: state.features.features.hasOwnProperty('reporting_dashboard_v2') && state.features.features.reporting_dashboard_v2.enabled
  }

  return props
}

export default connect(mapStateToProps)(AuthLinks)
